



























































































































import {axiosDelete, axiosGet, axiosPatch} from '@/utils/axiosWrapper';
import Vue from 'vue';
import {IDonor} from '../../../../api/models/donor'

export default Vue.extend({
  name: 'Donors',
  data() {
    return {
      donors: [] as IDonor[]
    }
  },
  methods: {
    async loadDonors() {
      this.donors = (await axiosGet('/donor/')).data
    },
    deleteEntry: async function (id: string) {
      const deleted = await axiosDelete('/donor/' + id)
      console.log(deleted)
      await this.loadDonors()
    },
    async increaseStatus(id: string) {
      await axiosPatch('/donor/status/increase', {_id: id})
      await this.loadDonors()
    }
  },
  async mounted() {
    await this.loadDonors()
  }
});
